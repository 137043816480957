.landscape-header-logo {
  display: flex;
  align-items: center;
  font-size: 4vmin;
  height: 5vmin;
}

.landscape-header-button {
  padding-right: 2%;
}
