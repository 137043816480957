.app-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  width: 100vw !important;
  background-color: black !important;
  background: radial-gradient(farthest-corner at 0px 0px, #071107, transparent),
    radial-gradient(ellipse at center, #3e8153, transparent) !important;
}

.inner-app-container {
  width: 85vw;
  height: 85vh !important;
  border: 0.75vmin solid white;

  border-radius: 20px;

  padding: 1%;
  overflow: scroll;
}

.body-container {
  height: 100%;
}
