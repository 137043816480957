.landscape-body {
  height: 100%;
  padding: 2%;
  /* padding-left: 3%; */
}

.landscape-body-left {
  height: 100%;
  /* padding-left: 3%; */
}
