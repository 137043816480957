@font-face {
  font-family: GelicaSemibold;
  src: url("./assets/fonts/GelicaSemibold.otf") format("opentype");
}

body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: GelicaSemibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: GelicaSemibold;
}
