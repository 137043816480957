.careers-button {
  cursor: pointer;
  border: 0.35vmin solid white;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vmin !important;
  border-radius: 1.5vmin;
  background-color: #2b502b;
  color: white;
  text-decoration: none;
}

.careers-button:hover {
  background-color: transparent;
}
