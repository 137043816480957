@font-face {
  font-family: GelicaSemibold;
  src: url(/static/media/GelicaSemibold.12213db6.otf) format("opentype");
}

body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: GelicaSemibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: GelicaSemibold;
}

.logo-build {
  width: 90%;
}

.careers-button {
  cursor: pointer;
  border: 0.35vmin solid white;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2vmin !important;
  border-radius: 1.5vmin;
  background-color: #2b502b;
  color: white;
  text-decoration: none;
}

.careers-button:hover {
  background-color: transparent;
}

.copyright-text {
  position: fixed;
  bottom: 1.5vmin;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 1.5vmin;
  color: rgba(255, 255, 255, 0.1);
  font-style: italic;
}

.slogan-description {
  font-family: "Inter", sans-serif !important;
  font-size: 2.5vmin;
  padding-top: 5% !important;
  padding-bottom: 2%;
}

.slogan-description-portrait {
  text-align: center;
}

.logo-mint {
  height: 5vmin;
  padding: 3%;
}

.slogan {
  font-size: 8vmin;
}

.slogan-portrait {
  text-align: center;
}
.slogan-refreshing {
  color: #74face;
  text-shadow: 1px 1px 15px #65d692;
}

.landscape-body {
  height: 100%;
  padding: 2%;
  /* padding-left: 3%; */
}

.landscape-body-left {
  height: 100%;
  /* padding-left: 3%; */
}

.landscape-header-logo {
  display: flex;
  align-items: center;
  font-size: 4vmin;
  height: 5vmin;
}

.landscape-header-button {
  padding-right: 2%;
}

.portrait-body {
  height: 100%;
  padding-top: 5%;
}

.portrait-header-logo {
  display: flex;
  align-items: center;
  font-size: 4vmin;
  height: 5vmin;
}

.portrait-header-button {
  padding-right: 2%;
}

.landscape-container {
  height: 95%;
}

.portrait-container {
  height: 95%;
}

.app-container {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh !important;
  width: 100vw !important;
  background-color: black !important;
  background: radial-gradient(farthest-corner at 0px 0px, #071107, transparent),
    radial-gradient(ellipse at center, #3e8153, transparent) !important;
}

.inner-app-container {
  width: 85vw;
  height: 85vh !important;
  border: 0.75vmin solid white;

  border-radius: 20px;

  padding: 1%;
  overflow: scroll;
}

.body-container {
  height: 100%;
}

