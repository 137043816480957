.slogan {
  font-size: 8vmin;
}

.slogan-portrait {
  text-align: center;
}
.slogan-refreshing {
  color: #74face;
  text-shadow: 1px 1px 15px #65d692;
}
