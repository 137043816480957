.slogan-description {
  font-family: "Inter", sans-serif !important;
  font-size: 2.5vmin;
  padding-top: 5% !important;
  padding-bottom: 2%;
}

.slogan-description-portrait {
  text-align: center;
}
